.fc .fc-button-primary {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #96C2C9);
    background-color: var(--fc-button-bg-color, #96C2C9);
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:active:hover {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: var(--fc-button-bg-color, #79A0A7);
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:hover {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: var(--fc-button-bg-color, #79A0A7);
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:disabled {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: var(--fc-button-bg-color, #BDD9DE);
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #BDD9DE);
}

.fc th {
    text-align: center;
    background-color: #BDD9DE;
    border-color: #95C2C9;
    color: #5C5C5C;
}

.fc .fc-toolbar-title {
    color: #5C5C5C;
    text-transform: capitalize;
}

.fc-h-event {
    display: block;
    border: 1px solid #96C2C9;
    border: 1px solid var(--fc-event-border-color, #96C2C9);
    background-color: #96C2C9;
    background-color: var(--fc-event-bg-color, #96C2C9);
}

.fc-h-event .fc-event-main {
    color: #fff;
    color: var(--fc-event-text-color, #fff);
}