@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: 'Red Hat Display' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-title {
  font-family: 'Red Hat Display';
}
/*
 Remueve botoncitos de flechas en los inputs que tengan type="number"
*/ 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
/* Animaciones para el CSSAnimation */

body {
  overflow: hidden;
}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 1000ms ease-in-out;
}
.fc .fc-button-primary {
    color: #fff;
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #96C2C9);
    background-color: #96C2C9;
    background-color: var(--fc-button-bg-color, #96C2C9);
    border-color: #96C2C9;
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:active:hover {
    color: #fff;
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: #79A0A7;
    background-color: var(--fc-button-bg-color, #79A0A7);
    border-color: #96C2C9;
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:hover {
    color: #fff;
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: #79A0A7;
    background-color: var(--fc-button-bg-color, #79A0A7);
    border-color: #96C2C9;
    border-color: #96C2C9;
    border-color: var(--fc-button-border-color, #96C2C9);
}

.fc .fc-button-primary:disabled {
    color: #fff;
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #79A0A7);
    background-color: #BDD9DE;
    background-color: var(--fc-button-bg-color, #BDD9DE);
    border-color: #96C2C9;
    border-color: #BDD9DE;
    border-color: var(--fc-button-border-color, #BDD9DE);
}

.fc th {
    text-align: center;
    background-color: #BDD9DE;
    border-color: #95C2C9;
    color: #5C5C5C;
}

.fc .fc-toolbar-title {
    color: #5C5C5C;
    text-transform: capitalize;
}

.fc-h-event {
    display: block;
    border: 1px solid #96C2C9;
    border: 1px solid #96C2C9;
    border: 1px solid var(--fc-event-border-color, #96C2C9);
    background-color: #96C2C9;
    background-color: #96C2C9;
    background-color: var(--fc-event-bg-color, #96C2C9);
}

.fc-h-event .fc-event-main {
    color: #fff;
    color: #fff;
    color: var(--fc-event-text-color, #fff);
}
